import React from 'react'
import {useTranslation} from 'react-i18next'

import {Box} from '@mui/material'
import clsx from 'clsx'
import isBoolean from 'lodash/isBoolean'
import {Switch} from 'ui-lib'

import {MergedService, ServiceItem} from 'pages/Company/types'
import {FieldTypes, Id} from 'types'

import useServiceConfigCardStyles from './styles'

interface ServiceCardProps {
  serviceItem: MergedService & ServiceItem
  handleSwitch?: (serviceId: Id, fieldType: FieldTypes) => void
  disabled?: boolean
  isEditable?: boolean
  namePrefix: string
}

const ServiceConfigCard: React.FC<ServiceCardProps> = ({
  serviceItem,
  handleSwitch,
  isEditable = true,
  disabled = false,
  namePrefix,
}) => {
  const {t} = useTranslation()
  const classes = useServiceConfigCardStyles()

  const isLimitedAccessState = !handleSwitch
    ? t(serviceItem?.isLimitedAccess ? 'administration.access-on' : 'administration.access-off')
    : ''
  const combinedTranslation = `${t('administration.access')} ${isLimitedAccessState}`
  const isActiveForm = isEditable || serviceItem.isActive

  const canRenderLimitedAccessSwitcher =
    (serviceItem?.isLimitedAccess || isEditable) && isBoolean(serviceItem?.isLimitedAccess)
  const canRenderCheckListSwitcher =
    (serviceItem?.isCheckListEnabled || isEditable) && isBoolean(serviceItem?.isCheckListEnabled)
  const canRenderDismissalRequestSwitcher =
    (serviceItem?.isDismissalRequestEnabled || isEditable) && isBoolean(serviceItem?.isDismissalRequestEnabled)
  const canRenderCompensationSwitcher =
    (serviceItem?.isEnableCompensation || isEditable) && isBoolean(serviceItem?.isEnableCompensation)
  const canRenderDocumentSignViaDijaSwitcher =
    (serviceItem?.isDocumentSignViaDijaEnabled || isEditable) && isBoolean(serviceItem?.isDocumentSignViaDijaEnabled)

  const canRenderReceivingMethodSwitcher =
    ((serviceItem?.isEnableReceivingMethod || isEditable) && isBoolean(serviceItem?.isEnableReceivingMethod)) ||
    !!serviceItem.receivingMethods?.length

  return (
    <Box className={clsx(classes.serviceCard, {[classes.notActive]: !isActiveForm})}>
      {canRenderLimitedAccessSwitcher && (
        <Box className={classes.serviceCardRow}>
          {typeof handleSwitch === 'function' && (
            <Switch
              disabled={disabled}
              isUpdating={false}
              checked={Boolean(serviceItem?.isLimitedAccess)}
              onChange={() => handleSwitch(serviceItem.id, 'isLimitedAccess')}
              name={`${namePrefix}.isLimitedAccess`}
            />
          )}
          <Box>{combinedTranslation}</Box>
        </Box>
      )}

      {canRenderCheckListSwitcher && (
        <Box className={classes.serviceCardRow}>
          {typeof handleSwitch === 'function' && (
            <Switch
              disabled={disabled}
              isUpdating={false}
              checked={Boolean(serviceItem?.isCheckListEnabled)}
              onChange={() => handleSwitch(serviceItem.id, 'isCheckListEnabled')}
              name={`${namePrefix}.isCheckListEnabled`}
            />
          )}
          <Box>{t('administration.checkList')}</Box>
        </Box>
      )}

      {canRenderDismissalRequestSwitcher && (
        <Box className={classes.serviceCardRow}>
          {typeof handleSwitch === 'function' && (
            <Switch
              disabled={disabled}
              isUpdating={false}
              checked={Boolean(serviceItem?.isDismissalRequestEnabled)}
              onChange={() => handleSwitch(serviceItem.id, 'isDismissalRequestEnabled')}
              name={`${namePrefix}.isDismissalRequestEnabled`}
            />
          )}
          <Box>{t('administration.dismissalRequest')}</Box>
        </Box>
      )}

      {canRenderDocumentSignViaDijaSwitcher && (
        <Box className={classes.serviceCardRow}>
          {typeof handleSwitch === 'function' && (
            <Switch
              disabled={disabled}
              isUpdating={false}
              checked={Boolean(serviceItem?.isDocumentSignViaDijaEnabled)}
              onChange={() => handleSwitch(serviceItem.id, 'isDocumentSignViaDijaEnabled')}
              name={`${namePrefix}.isDocumentSignViaDijaEnabled`}
            />
          )}
          <Box>{t('administration.diya-signature')}</Box>
        </Box>
      )}

      {canRenderCompensationSwitcher && (
        <Box className={classes.serviceCardRow}>
          {typeof handleSwitch === 'function' && (
            <Switch
              disabled={disabled}
              isUpdating={false}
              checked={Boolean(serviceItem?.isEnableCompensation)}
              onChange={() => handleSwitch(serviceItem.id, 'isEnableCompensation')}
              name={`${namePrefix}.isEnableCompensation`}
            />
          )}
          <Box>{t('administration.compensations')}</Box>
        </Box>
      )}

      {canRenderReceivingMethodSwitcher && (
        <Box className={classes.serviceCardRow}>
          {typeof handleSwitch === 'function' && (
            <Switch
              disabled
              isUpdating={false}
              checked
              onChange={() => handleSwitch(serviceItem.id, 'isEnableOfficeReceivingMethod')}
              name={`${namePrefix}.isEnableOfficeReceivingMethod`}
            />
          )}
          <Box>{t('administration.office')}</Box>
        </Box>
      )}

      {canRenderReceivingMethodSwitcher && (
        <Box className={classes.serviceCardRow}>
          {typeof handleSwitch === 'function' && (
            <Switch
              disabled={disabled}
              isUpdating={false}
              checked={Boolean(serviceItem?.isEnableAddressReceivingMethod)}
              onChange={() => handleSwitch(serviceItem.id, 'isEnableAddressReceivingMethod')}
              name={`${namePrefix}.isEnableAddressReceivingMethod`}
            />
          )}
          <Box>{t('administration.address')}</Box>
        </Box>
      )}

      {canRenderReceivingMethodSwitcher && (
        <Box className={classes.serviceCardRow}>
          {typeof handleSwitch === 'function' && (
            <Switch
              disabled={disabled}
              isUpdating={false}
              checked={Boolean(serviceItem?.isEnableDigitalReceivingMethod)}
              onChange={() => handleSwitch(serviceItem.id, 'isEnableDigitalReceivingMethod')}
              name={`${namePrefix}.isEnableDigitalReceivingMethod`}
            />
          )}
          <Box>{t('administration.digital')}</Box>
        </Box>
      )}

      {canRenderReceivingMethodSwitcher && (
        <Box className={classes.serviceCardRow}>
          {typeof handleSwitch === 'function' && (
            <Switch
              disabled={disabled}
              isUpdating={false}
              checked={Boolean(serviceItem?.isEnableEReferenceReceivingMethod)}
              onChange={() => handleSwitch(serviceItem.id, 'isEnableEReferenceReceivingMethod')}
              name={`${namePrefix}.isEnableEReferenceReceivingMethod`}
            />
          )}
          <Box>{t('administration.e-reference')}</Box>
        </Box>
      )}
    </Box>
  )
}

export default ServiceConfigCard
