import {makeStyles} from '@mui/styles'
import {colors} from 'ui-lib'

interface StyleProps {
  error?: boolean
}

export const useUploadingFile = makeStyles<void, StyleProps>(() => ({
  documentList: {
    display: 'grid',
    rowGap: 16,
    padding: 0,
    margin: 0,
  },
  deleteBtn: {
    color: colors.gray500,
  },
  error: {
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 'normal',
    color: colors.error,
    marginTop: 3,
  },
  spinner: {
    '&  div': {
      width: 'fit-content',
      margin: 'auto',
      position: 'initial',
    },
    '& span': {
      position: 'initial',
    },
  },
}))

export const useDocumentItemStyles = makeStyles(() => ({
  document: {
    width: '100%',
    height: 88,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 10px 0px 40px',
    backgroundColor: colors.white,
    borderRadius: 4,
    boxShadow: `0 2px 4px 0 ${colors.gray300}`,
    border: `solid 1px ${colors.gray60}`,
    justifyContent: 'space-between',
  },
  nameBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  subName: {
    fontSize: 12,
    lineHeight: '16px',
    color: colors.gray350,
  },
  iconBox: {
    marginLeft: '10px',
    display: 'flex',
  },
}))
