import React from 'react'

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import {Box} from '@mui/material'
import {Button} from 'ui-lib'

import {useUploadStyles} from './styles'

interface UploadProps {
  uploadButtonText: string
  dragAndDrop?: boolean
  accept?: string
  multiple?: boolean
  onChange?: (file: File[]) => void
  disabled?: boolean
  error?: boolean
  inputName?: string
}

const Upload: React.FC<UploadProps> = ({
  accept,
  multiple,
  onChange,
  disabled,
  uploadButtonText,
  error,
  dragAndDrop = false,
  inputName,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [dragActive, setDragActive] = React.useState(false)
  const classes = useUploadStyles({active: dragActive, error})

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && onChange) {
      onChange(Array.from(e.dataTransfer.files))
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.target.files && onChange) {
      onChange(Array.from(e.target.files))
      e.target.value = ''
    }
  }

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <div className={classes.container} onDragEnter={e => dragAndDrop && handleDrag(e)}>
      <input
        ref={inputRef}
        type="file"
        name={inputName}
        id="input-file-upload"
        multiple={multiple}
        onChange={handleChange}
        accept={accept}
        hidden
      />

      {!disabled && (
        <div className={classes.label} id="label-file-upload">
          <Box className={classes.field}>
            <Button
              fullWidth
              className={classes.addFileBtn}
              htmlType="button"
              type="cart-secondary"
              startIcon={<DescriptionOutlinedIcon />}
              data-test="add-file-btn"
              component="label"
              onClick={handleButtonClick}
              disabled={disabled}
            >
              {uploadButtonText}
            </Button>
          </Box>
        </div>
      )}
      {dragActive && (
        <div
          className={classes.dragFileElement}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </div>
  )
}

export default Upload
