import React from 'react'
import {useTranslation} from 'react-i18next'

import {Box, IconButton, ListItem} from '@mui/material'
import clsx from 'clsx'
import {Typography} from 'ui-lib'

import {useDocumentItemStyles} from './styles'

interface Control {
  Icon: React.ElementType
  onClick: () => void
  label?: string
  classes?: string
  canSee: boolean
}

interface DocumentItemProps extends React.HTMLAttributes<HTMLElement> {
  fileName: string
  controls?: Control[]
}

const DocumentItem: React.FC<DocumentItemProps> = ({fileName, controls, className, ...props}) => {
  const classes = useDocumentItemStyles()
  const {t} = useTranslation()
  const maxLengthFileName = 100
  const formattedFileName =
    fileName.length > maxLengthFileName ? `${fileName.slice(0, maxLengthFileName)}...` : fileName

  return (
    <ListItem className={clsx(classes.document, className)} {...props}>
      <Box className={classes.nameBlock} data-test="file-name">
        <Typography className={classes.subName} variant="subtitle2">
          {t('upload-file.document-sub-name')}
        </Typography>
        {formattedFileName}
      </Box>

      {controls && (
        <Box className={classes.iconBox}>
          {controls?.map(({Icon, onClick, label, classes, canSee}) => {
            if (!canSee) return undefined
            return (
              <IconButton key={label} onClick={onClick} className={classes} aria-label={label} component="label">
                <Icon />
              </IconButton>
            )
          })}
        </Box>
      )}
    </ListItem>
  )
}

export default DocumentItem
