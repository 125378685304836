import {DefaultFile} from './UploadingFile'

export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, {type: contentType})
  return blob
}

const adaptBase64ToFileObj = (value: DefaultFile[]) => {
  const BlobArray = value?.map((fileUrl, i) => {
    const blob = b64toBlob(fileUrl.fileContents)
    const newFile = new File([blob], fileUrl.fileDownloadName, {type: fileUrl.contentType})
    return {id: fileUrl?.fileDownloadName || i.toString(), file: newFile}
  })
  return BlobArray
}

export const defaultFileToUrl = (logo?: DefaultFile) => {
  if (logo) {
    const fileObj = adaptBase64ToFileObj([logo])
    const iconUrl = fileObj && URL.createObjectURL(fileObj[0].file)
    return iconUrl
  }
  return undefined
}

export const validateSizeInPx = (file: File, size = {width: 150, height: 150}): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file)
    const img = new Image()
    img.onload = function () {
      URL.revokeObjectURL(url)
      const {width, height} = img
      const minWidth = size.width
      const minHeight = size.height

      const isSquare = minWidth === minHeight
      const checkForSquare = isSquare && width <= minWidth && height <= minHeight && height === width
      const checkOnMinWidthAndHeight = !isSquare && width <= minWidth && height <= minHeight

      if (checkForSquare || checkOnMinWidthAndHeight) {
        resolve(true)
      } else {
        resolve(false)
      }
    }

    img.onerror = function () {
      console.log('Failed to load the SVG file.')
      reject(new Error('Failed to load the SVG file.'))
    }
    img.src = url
  })
}

export default adaptBase64ToFileObj
